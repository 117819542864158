import { Box, Card, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as PrevisionIcon } from "../../assets/svg/project/prevision.svg";
import { useProject } from "../../contexts/project.context";
import { VerticalTab } from "../VerticalTabs";
import { AerialAccordion } from "../novos/Aerial/AerialAccordion";
import FinancialForm from "../novos/Aerial/FinancialAccordion/FinancialForm";
import { useQuery, useMutation, QueryClient } from "react-query";
import { toast } from "react-toastify";
import AerialService from "../../services/aerial.service";
import Notification from "../UI/Notification";
import { NotificationFields } from "../../types/notification";

const AerialStep = () => {
  const { loadingProject, showNotification } = useProject();
  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: "success",
    message: "",
  });
  function closeNotification() {
    setNotification({ ...notification, open: false });
  }
  const [aerialData, setAerialData] = useState({} as any);
  const [aerialId, setAerialId] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";
  const [optionActive, setOptionActive] = useState(0);
  const queryClient = new QueryClient();
  const { isLoading, error, data } = useQuery({
    queryKey: [`creative-data`],
    queryFn: () => AerialService.get(projectId),
    onSuccess: (data) => {
      setAerialData(data);
      setAerialId(data._id);
    },
  });

  const { mutate: updateAerial, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => AerialService.update(state._id, state),
    {
      onSuccess: () => {
        setNotification({
          open: true,
          severity: "success",
          message: "Salvo com sucesso!",
        });
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível salvar",
        });
      },
    }
  );
  const { mutate: createAerial, isLoading: isLoadingCreate } = useMutation(
    (state: any) => AerialService.create(state),
    {
      onSuccess: (data) => {
        setAerialId(data._id);
        setNotification({
          open: true,
          severity: "success",
          message: "Salvo com sucesso!",
        });
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível salvar",
        });
      },
    }
  );
  function onSubmitFinancial(form: any) {
    form._id
      ? updateAerial({
          _id: aerialData._id,
          financialData: { ...form },
          project: projectId,
        })
      : createAerial({ financialData: { ...form }, project: projectId });
  }
  function updateAeriaData(form: any) {
    const tickets = form?.ticketsData;
    const invalidForm = tickets.some(
      (item: any) => !item?.outwardAirport || !item?.returnAirport
    );
    if (invalidForm) {
      showNotification(
        "Os Aeroportos de origem/destino são obrigatórios",
        "error"
      );
      return;
    }
    updateAerial(form);
  }
  function handleChangeTabs(index: number) {
    if (index === 1 && !aerialData._id) {
      return setNotification({
        open: true,
        severity: "warning",
        message: "Salve os dados de financeiro para prosseguir!",
      });
    }
    setOptionActive(index);
    queryClient.invalidateQueries();
  }
  const options = [
    {
      title: "Financeiro",
      description: "Adicione dados",
      icon: <PrevisionIcon />,
      template: (
        <FinancialForm
          onSubmit={onSubmitFinancial}
          financialData={aerialData?.financialData}
          projectId={projectId}
        />
      ),
    },
    {
      title: "Dados de passagem",
      description: "Adicione dados",
      icon: <PrevisionIcon />,
      template: (
        <AerialAccordion
          project={projectId}
          aerialId={aerialData?._id}
          ticketsData={aerialData?.ticketsData}
          onSubmit={updateAeriaData}
        />
      ),
    },
  ];

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={2}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      width={"100%"}
      overflow="auto"
    >
      <Notification
        notification={notification}
        closeNotification={closeNotification}
      />

      {loadingProject && (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      )}

      <Card
        sx={{
          display: "flex",
          mt: 1,
          mx: 5,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          maxWidth: 1480,
        }}
      >
        {!loadingProject && (
          <>
            <VerticalTab.Root>
              {options.map(({ title, description, icon }, index) => (
                <VerticalTab.Item
                  key={index}
                  onClick={() => {
                    handleChangeTabs(index);
                  }}
                >
                  <VerticalTab.Icon
                    icon={icon}
                    isActive={optionActive === index}
                  />
                  <VerticalTab.Content
                    title={title}
                    description={description}
                  />
                </VerticalTab.Item>
              ))}
            </VerticalTab.Root>

            {options[optionActive] && options[optionActive].template}
          </>
        )}
      </Card>
    </Box>
  );
};

export default AerialStep;
