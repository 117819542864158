import { Box } from "@mui/material";
import LogisticsTypesTabItem from "../LogisticsTypesTabItem";
import {
  RiCustomerService2Line,
  RiHotelBedLine,
  RiRestaurantLine,
  RiTranslate,
} from "react-icons/ri";
import { FaRegCalendarCheck, FaRegHandPointer } from "react-icons/fa";
import {
  MdOutlineAirplaneTicket,
  MdOutlineTransferWithinAStation,
} from "react-icons/md";
import { BsSignpost } from "react-icons/bs";
import { PiDotsThreeOutline } from "react-icons/pi";
import { useEffect, useState } from "react";

type PropTypes = {
  logisticTypes: any;
  onChange(newList: any): void;
  isDisabled: boolean;
};

const LogisticTypesTab: React.FC<PropTypes> = ({
  logisticTypes,
  onChange,
  isDisabled,
}: PropTypes) => {
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const [activeMuted, setActiveMuted] = useState(false);
  const [activeLogisticTypes, setActiveLogisticTypes] = useState({
    _id: logisticTypes._id || "",
    briefing_id: logisticTypes.briefing_id || "",
    project: logisticTypes.project || "",
    accommodations: logisticTypes.accommodations || null,
    rooms: logisticTypes.rooms || null,
    food_and_drinks: logisticTypes.food_and_drinks || null,
    equipments: logisticTypes.equipments || null,
    simultaneous_translation: logisticTypes.simultaneous_translation || null,
    support_teams: logisticTypes.support_teams || null,
    subscription: logisticTypes.subscription || null,
    rsvp: logisticTypes.rsvp || null,
    air: logisticTypes.air || null,
    transfer: logisticTypes.transfer || null,
    comunication: logisticTypes.comunication || null,
    several: logisticTypes.several || null,
  });

  useEffect(() => {
    setActiveLogisticTypes({
      _id: logisticTypes._id || "",
      briefing_id: logisticTypes.briefing_id || "",
      project: logisticTypes.projectId,
      accommodations: logisticTypes.accommodations,
      rooms: logisticTypes.rooms,
      food_and_drinks: logisticTypes.food_and_drinks,
      equipments: logisticTypes.equipments,
      simultaneous_translation: logisticTypes.simultaneous_translation,
      support_teams: logisticTypes.support_teams,
      subscription: logisticTypes.subscription,
      rsvp: logisticTypes.rsvp,
      air: logisticTypes.air,
      transfer: logisticTypes.transfer,
      comunication: logisticTypes.comunication,
      several: logisticTypes.several,
    });
  }, [logisticTypes]);

  useEffect(() => {
    setActiveMuted(true);
  }, [activeLogisticTypes]);

  const changeIsActive = (key: string, value: boolean) => {
    const newList = {
      ...logisticTypes,
      [key]: value,
    };
    setActiveMuted(false);
    //setActiveLogisticTypes(newList);
    onChange(newList);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "25%",
        padding: "0 5px",
        borderRight: "1px solid #ddd",
        gap: 2,
      }}
    >
      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiHotelBedLine size={20} />}
        title={"Hospedagem"}
        isActive={activeLogisticTypes.accommodations}
        typeKey="accommodations"
        changeIsActive={changeIsActive}
      />
      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiHotelBedLine size={20} />}
        title={"Salas"}
        isActive={activeLogisticTypes.rooms}
        typeKey="rooms"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiRestaurantLine size={20} />}
        title={"A & B e Catering"}
        isActive={activeLogisticTypes.food_and_drinks}
        typeKey="food_and_drinks"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiTranslate size={20} />}
        title={"Equipamentos"}
        isActive={activeLogisticTypes.equipments}
        typeKey="equipments"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiTranslate size={20} />}
        title={"Tradução simultânea"}
        isActive={activeLogisticTypes.simultaneous_translation}
        typeKey="simultaneous_translation"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<RiCustomerService2Line size={20} />}
        title={"Equipe e suporte"}
        isActive={activeLogisticTypes.support_teams}
        typeKey="support_teams"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<FaRegHandPointer size={20} />}
        title={"Inscrição"}
        isActive={activeLogisticTypes.subscription}
        typeKey="subscription"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<FaRegCalendarCheck size={20} />}
        title={"RSVP"}
        isActive={activeLogisticTypes.rsvp}
        typeKey="rsvp"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<MdOutlineAirplaneTicket size={20} />}
        title={"Aéreo"}
        isActive={activeLogisticTypes.air}
        typeKey="air"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<MdOutlineTransferWithinAStation size={20} />}
        title={"Transfer"}
        isActive={activeLogisticTypes.transfer}
        typeKey="transfer"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<BsSignpost size={20} />}
        title={"Comunicação"}
        isActive={activeLogisticTypes.comunication}
        typeKey="comunication"
        changeIsActive={changeIsActive}
      />

      <LogisticsTypesTabItem
        isDisabled={isDisabled || !activeMuted}
        icon={<PiDotsThreeOutline size={20} />}
        title={"Outros"}
        isActive={activeLogisticTypes.several}
        typeKey="several"
        changeIsActive={changeIsActive}
      />
    </Box>
  );
};

export default LogisticTypesTab;
