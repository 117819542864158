import { currencyFormatter } from "../../utils/currencyFormatter";

export const formatter = (formatted_value: any = 0) => {
  return `${currencyFormatter.format(formatted_value)}`;
};

export const formatBRLCurrency = (value: number) => {
  const formattedNumber: string = `R$ ${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}`;
  return formattedNumber;
};

export const formatPercentage = (value: number = 0) => {
  if (value < 0) return "0,00 %";
  return `${value?.toFixed(2)?.replace(".", ",")} %`;
};

interface ISummaryTotals {
  logisticTotal: number;
  airTotal: number;
  totalFees: number;
  totalMarkupAndOverhead: number;
  comissionTotal: number;
  totalRevenue: number;
  totalTaxes: number;
  totalTaxesFee: number;
  totalRevenueMinusTaxes: number;
  profitability: number;
  supplierCostTotal: number;
  invoicedMM: number;
  taxPercentage: number;
  additionalTaxes: number;
}

export const getSummaryTotals = (formWatch: any): ISummaryTotals => {
  const MIN_TAX_PERCENTAGE = 6.15;

  const logisticTotal =
    (formWatch.logisticFee || 0) +
    (formWatch.logisticComission || 0) +
    (formWatch.logisticMarkup || 0);
  const airTotal =
    (formWatch.airFee || 0) +
    (formWatch.airComission || 0) +
    (formWatch.airMarkup || 0);

  const totalFees =
    (formWatch.airFee || 0) +
    (formWatch.logisticFee || 0) +
    (formWatch.RSVP || 0) +
    (formWatch.communicationFee || 0);

  const totalMarkupAndOverhead =
    (formWatch.logisticMarkup || 0) + (formWatch.airMarkup || 0);

  const comissionTotal =
    (formWatch.logisticComission || 0) + (formWatch.airComission || 0);

  const totalRevenue =
    logisticTotal +
    airTotal +
    (formWatch.RSVP || 0) +
    (formWatch.communicationFee || 0) +
    (formWatch.additionalTaxes || 0);

  const totalTaxes =
    (comissionTotal + totalMarkupAndOverhead + totalFees) *
    (MIN_TAX_PERCENTAGE / 100);

  const totalTaxesFee = totalRevenue * (MIN_TAX_PERCENTAGE / 100);

  const totalRevenueMinusTaxes = totalRevenue - totalTaxesFee;

  const profitability =
    (totalRevenueMinusTaxes / (formWatch.totalValueOfEvents || 0)) * 100;

  const supplierCostTotal =
    (formWatch.totalValueOfEvents || 0) -
    ((formWatch.directPaymentOfClients || 0) +
      totalMarkupAndOverhead +
      (formWatch.airFee || 0) +
      (formWatch.logisticFee || 0) +
      (formWatch.RSVP || 0) +
      (formWatch.communicationFee || 0));

  const invoicedMM =
    (formWatch.totalValueOfEvents || 0) -
    (formWatch.directPaymentOfClients || 0);

  const newTaxPercentage =
    (((formWatch.additionalTaxes || 0) + (formWatch.totalTaxes || 0)) /
      (comissionTotal + totalMarkupAndOverhead + totalFees)) *
    100;

  const invisibleTaxes =
    (comissionTotal + totalMarkupAndOverhead + totalFees) *
    ((formWatch.taxPercentage || 0) / 100);

  const newAdditionalTaxes = invisibleTaxes - totalTaxes;

  return {
    logisticTotal,
    airTotal,
    totalFees,
    totalMarkupAndOverhead,
    comissionTotal,
    totalRevenue,
    totalTaxes,
    totalTaxesFee,
    totalRevenueMinusTaxes,
    profitability: profitability <= 0 ? 0 : profitability,
    supplierCostTotal,
    invoicedMM,
    additionalTaxes: newAdditionalTaxes,
    taxPercentage: newTaxPercentage,
  };
};
