import { Button } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { BudgetStatus } from "../../../../types/budget/budget";

interface IReplicateSummaryProps {
  fromStatus: any;
  setShowReplicate: React.Dispatch<React.SetStateAction<boolean>>;
  onReplicate: () => void;
}

const ReplicateSummary = ({
  fromStatus,
  setShowReplicate,
  onReplicate,
}: IReplicateSummaryProps) => {
  const statusLabels: any = {
    [BudgetStatus.ON_APPROVAL]: "Em aprovação",
    [BudgetStatus.APPROVED]: "Aprovado",
    [BudgetStatus.CLOSURE]: "Fechamento",
  };

  return (
    <div
      style={{
        backgroundColor: "#E8F2FC",
        display: "flex",
        flexDirection: "column",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 16,
        }}
      >
        <span>
          Gostaria de replicar os dados da etapa{" "}
          <strong>{statusLabels[fromStatus]}</strong> nos campos abaixo?
        </span>
        <IoMdClose
          color="#222222"
          size={20}
          onClick={() => setShowReplicate(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: 16,
          gap: 8,
        }}
      >
        <Button
          style={{
            color: "#1361A4",
            background: "#FFFFFF",
            width: "150px",
            fontSize: 15,
            border: "1px solid #1361A4",
            fontWeight: 500,
          }}
          onClick={() => setShowReplicate(false)}
        >
          Não replicar
        </Button>
        <Button
          style={{
            color: "#1361A4",
            background: "#FFFFFF",
            width: "150px",
            fontSize: 15,
            border: "1px solid #1361A4",
            fontWeight: 500,
          }}
          onClick={onReplicate}
        >
          Sim, replicar
        </Button>
      </div>
    </div>
  );
};

export default ReplicateSummary;
