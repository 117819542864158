import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMaterial,
  SelectProps,
} from "@mui/material";
import React, { forwardRef, useState } from "react";

interface Props extends SelectProps {
  helperText?: string;
  customLabel?: boolean;
}

const SelectForm = forwardRef<HTMLInputElement, Props>(
  ({ label, children, helperText, customLabel = false, ...props }, ref) => {
    const [displayLabel, setDisplayLabel] = useState(true);
    return (
      <Box width={"100%"} margin={"0 1"}>
        <span
          style={{
            fontSize: "13px",
            paddingBottom: "10px",
            color: "#00000099",
          }}
        >
          {label}
        </span>
        {!customLabel && displayLabel && (
          <InputLabel sx={{ mt: 1.5 }}>
            {props.placeholder || "Clique para Selecionar"}
          </InputLabel>
        )}
        <SelectMaterial
          {...props}
          onFocus={() => setDisplayLabel(false)}
          onBlur={() => {
            !!props.value ? null : setDisplayLabel(true);
          }}
          fullWidth
          size="small"
          MenuProps={{ style: { maxHeight: 450 } }}
        >
          <MenuItem value="">
            <em>Não Selecionado</em>
          </MenuItem>
          {children}
        </SelectMaterial>

        {props.error && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </Box>
    );
  }
);

export default SelectForm;
