import { Button } from "@mui/material";
import { useState } from "react";
import { GiCancel } from "react-icons/gi";

import CustomModal from "../../../CustomModal";
import { ReactComponent as CircleInfo } from "../../../../assets/svg/project/alert.svg";

interface IUnsavedChangesModalProps {
  handleClose(target: string): void;
  isOpen: boolean;
  makeDecision(flag: boolean): void;
}

export function UnsavedChangesModal({
  makeDecision,
  isOpen,
  handleClose,
}: IUnsavedChangesModalProps) {
  const [hideAlert, setHideAlert] = useState(false);
  function _makeDecision(flag: boolean) {
    if (hideAlert === true) {
      localStorage.setItem("hideAlert", "0");
    }
    makeDecision(flag);
  }
  return (
    <CustomModal handleClose={handleClose} isOpen={isOpen} target="alertModal">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <CircleInfo fontSize={20} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: "8px 16px",
          }}
        >
          <span style={{ fontSize: 24, color: "#4B465C", fontWeight: 500 }}>
            Salvar alterações antes de prosseguir?
          </span>
          <div>
            Parece que você fez alterações nos campos dessa tela e não salvou.
            Em caso de dúvidas cancele e revise o que foi alterado.
          </div>
        </div>
        <div style={{ cursor: "pointer" }}>
          <GiCancel
            size={20}
            onClick={() => {
              handleClose("alertModal");
            }}
            style={{ marginTop: 10 }}
          />
        </div>
      </div>
      <br />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        <Button
          style={{
            background: "#D32F2F",
            color: "#FFFFFF",
            width: "150px",
            marginRight: "15px",
            fontSize: 14,
          }}
          onClick={() => {
            _makeDecision(false);
          }}
        >
          Descartar alterações
        </Button>

        <Button
          style={{
            background: "#1361A4",
            color: "#FFFFFF",
            width: "150px",
            fontSize: 14,
          }}
          onClick={() => {
            _makeDecision(true);
          }}
        >
          Salvar alterações
        </Button>
      </div>
    </CustomModal>
  );
}
