import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { currencyFormatter } from "../../../utils/currencyFormatter";

interface Props extends NumberFormatProps {
  label: string;
  value?: number;
  error?: boolean;
  helperText?: string;
  onChange: (e: any) => void;
  totalsRef?: any;
}

const CurrencyInput = React.forwardRef<HTMLInputElement, Props>(
  ({ label, onChange, totalsRef, name, ...props }: Props, ref) => {
    const [value, setValue] = useState<string | number>("");
    const [isDirty, setIsDirty] = useState(false);
    const inputRef = useRef<any>(ref);

    useEffect(() => {
      if (props.value && props.value !== value && !value && !isDirty) {
        let valueNumber = props.value;

        if (!String(props.value).includes(".")) {
          valueNumber = props.value * 100;
        }

        valueNumber = parseInt(String(props.value.toFixed(2)).replace(".", ""));
        handleChange({ value: valueNumber, floatValue: props.value });
      }
    }, [props.value]);

    const handleChange = async (v: any) => {
      if (v.floatValue) {
        setValue(parseFloat(v.value) * 100);
        // Set the value to value * 100 because it was divided on the field value prop
        onChange && onChange({ ...v, floatValue: v.floatValue / 100 });
      } else {
        setValue("");
        onChange && onChange({ ...v, floatValue: 0 });
      }
    };

    const formatter = (formatted_value: any = 0) => {
      if (name && totalsRef?.current?.[name]) {
        if (totalsRef?.current?.[name] < 0)
          return `-${currencyFormatter.format(formatted_value / 100)}`;
      }
      // Set to 0,00 when "" and divide by 100 to start by the cents when start typing
      return `${currencyFormatter.format(formatted_value / 100)}`;
    };

    const keyDown = (e: any) => {
      setIsDirty(true);
      //This if keep the cursor position on erase if the value is === 0
      if (e.code === "Backspace" && !value) {
        e.preventDefault();
      }
      // This if sets the value to 0 and prevent the default for the cursor to keep when there's only cents
      if (e.code === "Backspace" && Number(value) < 1000) {
        e.preventDefault();
        setValue(0);
      }
    };

    return (
      <Box width={"100%"}>
        <InputLabel sx={{ fontSize: 13 }} error={props.error}>
          {label}
        </InputLabel>

        <NumberFormat
          {...props}
          inputRef={inputRef}
          value={Number(value) / 100}
          format={formatter}
          onValueChange={handleChange}
          prefix={"R$ "}
          allowEmptyFormatting
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          customInput={TextField}
          onKeyDown={keyDown}
          color={"info"}
          size="small"
        />
      </Box>
    );
  }
);

export default CurrencyInput;
