import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import CurrencyInput from "../UI/CurrencyInput";

interface Props {
  label: string;
  placeholder?: string;
  name: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  totalsRef?: any;
}

const CurrencyInputForm = ({
  label,
  name,
  error,
  helperText,
  placeholder,
  disabled,
  totalsRef,
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restField } }) => (
        <CurrencyInput
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          {...restField}
          onChange={({ floatValue }: { floatValue: number }) =>
            onChange(floatValue)
          }
          totalsRef={totalsRef}
        />
      )}
    />
  );
};

export default CurrencyInputForm;
