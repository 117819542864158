import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { forwardRef } from "react";

type InputProps = TextFieldProps & { marginTop?: number };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, marginTop = 2, ...props }: InputProps, ref) => {
    return (
      <Box width={"100%"} marginTop={marginTop}>
        <InputLabel sx={{ fontSize: 13, height: 18 }} error={props.error}>
          {label}
        </InputLabel>
        <TextField
          ref={ref}
          {...props}
          fullWidth
          sx={{ margin: 0, fontSize: 15 }}
          FormHelperTextProps={{ sx: { marginLeft: 0 } }}
          // InputProps={{
          //   endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          // }}
        />
      </Box>
    );
  }
);
