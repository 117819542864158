import { Box, InputLabel, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const DynamicInput = ({ name, value, label, ...rest }: any) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Box width={"100%"}>
          <InputLabel sx={{ fontSize: 13 }} error={rest.error}>
            {label}
          </InputLabel>
          <TextField {...rest} fullWidth value={value} onChange={onChange} />
        </Box>
      )}
    />
  );
};

export default DynamicInput;
