import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { date, object, string } from "yup";

import { useProject } from "../../../contexts/project.context";
import { ProjectStatus } from "../../../types";
import {
  BRIEFING_STATUS_OPTIONS,
  ALLOWED_STATUS_TRANSITION,
  ALLOWED_STATUS_RETURN,
} from "../../../utils/briefingStatus";
import DatePickerForm from "../../DatePickerForm";
import { Input } from "../../Input";
import SelectForm from "../../UI/Select";

interface ProjectStatusModalProps {
  isOpen: boolean;
  status?: ProjectStatus;
  onClose: () => void;
}

const schema = object({
  status: string().required("Status é obrigatório"),
  justification: string().required("Justificativa é obrigatório"),
  estimatedDate: date()
    .typeError("Data inválida")
    .required("Data previsão é obrigatório"),
}).required();

const ProjectStatusModal = ({
  status,
  isOpen,
  onClose,
}: ProjectStatusModalProps) => {
  const { updateStatus, summary } = useProject();
  const methods = useForm({
    defaultValues: {
      status: "",
      justification: "",
      estimatedDate: undefined,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = methods;

  async function onSubmit(form: any) {
    if (form?.status === status?.status) {
      toast.error("O projeto já está neste status");
      return;
    }

    if (form?.status === "APPROVED") {
      const hasFilledApproved =
        summary["APPROVED"]?.hasOwnProperty("totalValueOfEvents");
      if (!hasFilledApproved) {
        toast.error(
          "O resumo financeiro do status aprovado precisa estar preenchido."
        );
        return;
      }
    }

    const isReturnStatus = status
      ? ALLOWED_STATUS_RETURN?.[status?.status]?.includes(form?.status)
      : false;

    await updateStatus({ ...form, _id: status?._id }, isReturnStatus);
    onClose();
  }

  const allowedTransitions = status
    ? ALLOWED_STATUS_TRANSITION[status?.status]
      ? [status?.status, ...ALLOWED_STATUS_TRANSITION[status?.status]]
      : [status?.status]
    : [];

  const filteredStatusOptions = BRIEFING_STATUS_OPTIONS.filter((item) =>
    allowedTransitions.includes(item.value)
  );

  useEffect(() => {
    if (status)
      reset({
        status: status.status,
        justification: "",
        estimatedDate: undefined,
      });
  }, []);
  return (
    <FormProvider {...methods}>
      <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={"sm"}>
        <DialogTitle sx={{ m: 0, px: 4, py: 3, color: "#1361A4" }}>
          Alterar Status
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ px: 4 }}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} gap={4}>
                <SelectForm
                  label="Status *"
                  value={watch("status")}
                  {...register("status")}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  customLabel
                >
                  {filteredStatusOptions.map(({ value, text }, index) => (
                    <MenuItem key={index} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </SelectForm>

                <DatePickerForm
                  onBlur={() => {}}
                  label="Data  previsão *"
                  name="estimatedDate"
                  control={control}
                  error={errors.estimatedDate?.message}
                />
              </Box>

              <Input
                label="Justificativa *"
                {...register("justification")}
                error={!!errors.justification}
                helperText={errors.justification?.message}
              />
            </Box>
          </DialogContent>

          <DialogActions sx={{ px: 4, py: 3 }}>
            <Button type="submit">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};

export default ProjectStatusModal;
