import React, { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, InputLabel, TextField } from "@mui/material";

interface Props {
  label: string;
  placeholder?: string;
  name: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const InputForm = ({
  label,
  name,
  error,
  helperText,
  placeholder,
  disabled,
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restField } }) => (
        <Box width={"100%"} marginTop={0}>
          <InputLabel sx={{ fontSize: 13, height: 18 }}>{label}</InputLabel>
          <TextField
            {...restField}
            fullWidth
            sx={{ margin: 0, fontSize: 15 }}
            FormHelperTextProps={{ sx: { marginLeft: 0 } }}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        </Box>
      )}
    />
  );
};

export default InputForm;
