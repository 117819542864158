import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useProject } from "../../../../../contexts/project.context";
import ProjectSummaryService from "../../../../../services/projectSummary.service";
import { BudgetStatus } from "../../../../../types/budget/budget";

import { AlertModal } from "../../../../AlertModal";
import CurrencyInputForm from "../../../../CurrencyInputForm";
import SummarySection from "../../SummarySection";
import { ReactComponent as CompleteCircleIcon } from "../../../../../assets/svg/project/circle-arrow-complete.svg";
import { ReactComponent as IncompleteCircleIcon } from "../../../../../assets/svg/project/circle-arrow-incomplete.svg";
import { ReactComponent as CircleInfo } from "../../../../../assets/svg/project/circle-info.svg";
import { toast } from "react-toastify";
import InputForm from "../../../../InputForm";
import DynamicInput from "../../../../DynamicInput";
import NumberFormat from "react-number-format";
import {
  formatBRLCurrency,
  formatPercentage,
  formatter,
  getSummaryTotals,
} from "../../../utils";

const validationSchema = yup.object({
  totalValueOfEvents: yup.number(),
  directPaymentOfClients: yup.number(),
  observations: yup.string(),
  logisticFee: yup.number(),
  logisticMarkup: yup.number(),
  logisticComission: yup.number(),
  airFee: yup.number(),
  airMarkup: yup.number(),
  airComission: yup.number(),
  RSVP: yup.number(),
  communicationFee: yup.number(),
  totalTaxes: yup.number(),
  totalTaxesFee: yup.number(),
  additionalTaxes: yup.number(),
  taxPercentage: yup.number(),
  totalFees: yup.number(),
  totalMarkupAndOverhead: yup.number(),
  profitability: yup.number(),
  totalRevenue: yup.number(),
  invoicedMM: yup.number(),
  logisticTotal: yup.number(),
  airTotal: yup.number(),
  comissionTotal: yup.number(),
  supplierCostTotal: yup.number(),
  totalRevenueMinusTaxes: yup.number(),
});

const SUMMARY_STATUS = BudgetStatus.ON_APPROVAL;
const MIN_TAX_PERCENTAGE = 6.15;

const OnApprovalAccordion = () => {
  const {
    project,
    updateSummaryContext,
    summary,
    handleSetUnsavedSummary,
    showNotification,
  } = useProject();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";

  const [hasOpened, setHasOpened] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [alertModal, setAlertModal] = useState(false);
  const [tempForm, setTempForm] = useState({});
  const currentFormRef = useRef<any>({});
  const totalsRef = useRef<any>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [hasFilled, setHasFilled] = useState(false);
  const [lastFocus, setLastFocus] = useState("");
  const [formatValue, setFormattedValue] = useState("R$ 0,00");
  const [percent, setPercent] = useState<any>(0);

  const canEdit =
    project?.status?.status === "BUDGET" ||
    project?.status?.status === "ON_APROVAL";

  const methods = useForm({
    defaultValues: {
      totalValueOfEvents: 0,
      directPaymentOfClients: 0,
      observations: "",
      logisticFee: 0,
      logisticMarkup: 0,
      logisticComission: 0,
      airFee: 0,
      airMarkup: 0,
      airComission: 0,
      RSVP: 0,
      communicationFee: 0,
      totalTaxes: 0,
      totalTaxesFee: 0,
      additionalTaxes: 0,
      taxPercentage: MIN_TAX_PERCENTAGE,
      totalFees: 0,
      totalMarkupAndOverhead: 0,
      profitability: 0,
      totalRevenue: 0,
      invoicedMM: 0,
      logisticTotal: 0,
      airTotal: 0,
      comissionTotal: 0,
      supplierCostTotal: 0,
      totalRevenueMinusTaxes: 0,
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    watch,
    control,
  } = methods;

  const formWatch: yup.InferType<typeof validationSchema> = useWatch({
    control,
  });

  useEffect(() => {
    handleSetUnsavedSummary({ active: false, saveFn: null });
  }, []);

  useEffect(() => {
    const watch: any = formWatch;
    const hasChanges = Object.entries(currentFormRef.current).some(
      ([key, value]: any) => {
        if (key === "additionalTaxes") {
          return watch[key]?.toFixed(2) !== value?.toFixed(2);
        }

        return watch[key] != value;
      }
    );

    setHasChanges(hasChanges);
    handleSetUnsavedSummary({
      active: hasChanges,
      saveFn: hasChanges ? handleSubmit(onAlertSubmit) : null,
    });
  }, [formWatch]);

  useEffect(() => {
    if (formWatch) {
      const {
        logisticTotal,
        airTotal,
        totalFees,
        totalMarkupAndOverhead,
        comissionTotal,
        totalRevenue,
        totalTaxesFee,
        totalRevenueMinusTaxes,
        profitability,
        supplierCostTotal,
        invoicedMM,
        totalTaxes,
      } = getSummaryTotals(formWatch);

      setValue("logisticTotal", logisticTotal);
      setValue("airTotal", airTotal);
      setValue("totalFees", totalFees);
      setValue("totalMarkupAndOverhead", totalMarkupAndOverhead);
      setValue("comissionTotal", comissionTotal);
      setValue("totalRevenue", totalRevenue);
      setValue("totalTaxesFee", totalTaxesFee);
      setValue("totalRevenueMinusTaxes", totalRevenueMinusTaxes);
      setValue("profitability", isFinite(profitability) ? profitability : 0);
      setValue("supplierCostTotal", supplierCostTotal);
      setValue("invoicedMM", invoicedMM);
      setValue("totalTaxes", totalTaxes);
    }
  }, [
    formWatch.logisticFee,
    formWatch.logisticComission,
    formWatch.logisticMarkup,
    formWatch.airFee,
    formWatch.airComission,
    formWatch.airMarkup,
    formWatch.RSVP,
    formWatch.communicationFee,
    formWatch.additionalTaxes,
    formWatch.taxPercentage,
    formWatch.totalValueOfEvents,
    formWatch.directPaymentOfClients,
  ]);

  useEffect(() => {
    if (formWatch) {
      const { additionalTaxes } = getSummaryTotals(formWatch);

      if (lastFocus === "") {
        const formattedAdditionalTaxes = formatBRLCurrency(additionalTaxes);
        setFormattedValue(formattedAdditionalTaxes);
        setValue("additionalTaxes", formatNumber(additionalTaxes));
      }
    }
  }, [
    formWatch.logisticFee,
    formWatch.logisticComission,
    formWatch.logisticMarkup,
    formWatch.airFee,
    formWatch.airComission,
    formWatch.airMarkup,
    formWatch.RSVP,
    formWatch.communicationFee,
    formWatch.totalValueOfEvents,
    formWatch.directPaymentOfClients,
  ]);

  function formatNumber(value: number) {
    return Number(value.toFixed(3));
  }

  useEffect(() => {
    if (lastFocus === "value") {
      const totalFees =
        (formWatch.airFee || 0) +
        (formWatch.logisticFee || 0) +
        (formWatch.RSVP || 0) +
        (formWatch.communicationFee || 0);

      const totalMarkupAndOverhead =
        (formWatch.logisticMarkup || 0) + (formWatch.airMarkup || 0);

      const comissionTotal =
        (formWatch.logisticComission || 0) + (formWatch.airComission || 0);

      const newTaxPercentage =
        (((formWatch.additionalTaxes || 0) + (formWatch.totalTaxes || 0)) /
          (comissionTotal + totalMarkupAndOverhead + totalFees)) *
        100;
      setPercent(formatNumber(newTaxPercentage));
      setValue("taxPercentage", formatNumber(newTaxPercentage));
    }
  }, [formWatch.additionalTaxes]);

  useEffect(() => {
    if (lastFocus === "percentage") {
      const totalFees =
        (formWatch.airFee || 0) +
        (formWatch.logisticFee || 0) +
        (formWatch.RSVP || 0) +
        (formWatch.communicationFee || 0);

      const totalMarkupAndOverhead =
        (formWatch.logisticMarkup || 0) + (formWatch.airMarkup || 0);

      const comissionTotal =
        (formWatch.logisticComission || 0) + (formWatch.airComission || 0);

      const totalTaxes =
        (comissionTotal + totalMarkupAndOverhead + totalFees) *
        (MIN_TAX_PERCENTAGE / 100);

      const invisibleTaxes =
        (comissionTotal + totalMarkupAndOverhead + totalFees) *
        ((formWatch.taxPercentage || 0) / 100);

      const newAdditionalTaxes = invisibleTaxes - totalTaxes;

      const formattedAdditionalTaxes = formatBRLCurrency(newAdditionalTaxes);

      setFormattedValue(formattedAdditionalTaxes);
      setValue("additionalTaxes", formatNumber(newAdditionalTaxes));
    }
  }, [formWatch.taxPercentage]);

  useEffect(() => {
    const hasFilled =
      summary["ON_APPROVAL"]?.hasOwnProperty("totalValueOfEvents");
    setHasFilled(hasFilled);
  }, [summary]);

  const getSummary = async () => {
    reset();
    setLoading(true);
    const data = await ProjectSummaryService.get({
      project: projectId,
      status: SUMMARY_STATUS,
    });
    if (!data?.length) {
      await Promise.all([
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.ON_APPROVAL,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.APPROVED,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.CLOSURE,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.AUDITED,
        }),
      ]);
      getSummary();
    } else {
      setSummaryData(data?.[0]);
      updateSummaryContext("ON_APPROVAL", data?.[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasOpened) getSummary();
  }, [hasOpened]);

  useEffect(() => {
    setValue("totalValueOfEvents", summaryData?.totalValueOfEvents || 0);
    currentFormRef.current["totalValueOfEvents"] =
      summaryData?.totalValueOfEvents || 0;
    setValue(
      "directPaymentOfClients",
      summaryData?.directPaymentOfClients || 0
    );
    currentFormRef.current["directPaymentOfClients"] =
      summaryData?.directPaymentOfClients || 0;
    setValue("logisticFee", summaryData?.logisticFee || 0);
    currentFormRef.current["logisticFee"] = summaryData?.logisticFee || 0;
    setValue("logisticMarkup", summaryData?.logisticMarkup || 0);
    currentFormRef.current["logisticMarkup"] = summaryData?.logisticMarkup || 0;
    setValue("logisticComission", summaryData?.logisticComission || 0);
    currentFormRef.current["logisticComission"] =
      summaryData?.logisticComission || 0;
    setValue("airFee", summaryData?.airFee || 0);
    currentFormRef.current["airFee"] = summaryData?.airFee || 0;
    setValue("airMarkup", summaryData?.airMarkup || 0);
    currentFormRef.current["airMarkup"] = summaryData?.airMarkup || 0;
    setValue("airComission", summaryData?.airComission || 0);
    currentFormRef.current["airComission"] = summaryData?.airComission || 0;
    setValue("RSVP", summaryData?.RSVP || 0);
    currentFormRef.current["RSVP"] = summaryData?.RSVP || 0;
    setValue("communicationFee", summaryData?.communicationFee || 0);
    currentFormRef.current["communicationFee"] =
      summaryData?.communicationFee || 0;
    setValue("observations", summaryData?.observations || "");
    currentFormRef.current["observations"] = summaryData?.observations || "";
    setValue("taxPercentage", summaryData?.taxPercentage || MIN_TAX_PERCENTAGE);
    setPercent(summaryData?.taxPercentage || MIN_TAX_PERCENTAGE);
    currentFormRef.current["taxPercentage"] =
      summaryData?.taxPercentage || MIN_TAX_PERCENTAGE;

    setValue("totalTaxes", summaryData?.totalTaxes || 0);
    setValue("totalTaxesFee", summaryData?.totalTaxesFee || 0);

    setValue(
      "additionalTaxes",
      summaryData?.additionalTaxes < 0.001 ? 0 : summaryData?.additionalTaxes
    );
    currentFormRef.current["additionalTaxes"] =
      summaryData?.additionalTaxes < 0.001 ? 0 : summaryData?.additionalTaxes;

    if (summaryData?.additionalTaxes) {
      const formattedAdditionalTaxes = formatBRLCurrency(
        summaryData?.additionalTaxes
      );
      setFormattedValue(formattedAdditionalTaxes);
    }

    setValue("totalFees", summaryData?.totalFees || 0);
    setValue(
      "totalMarkupAndOverhead",
      summaryData?.totalMarkupAndOverhead || 0
    );
    setValue("profitability", summaryData?.profitability || 0);
    setValue("totalRevenue", summaryData?.totalRevenue || 0);
    setValue("invoicedMM", summaryData?.invoicedMM || 0);
    setValue("logisticTotal", summaryData?.logisticTotal || 0);
    setValue("airTotal", summaryData?.airTotal || 0);
    setValue("comissionTotal", summaryData?.comissionTotal || 0);
    setValue("supplierCostTotal", summaryData?.supplierCostTotal || 0);
    setValue(
      "totalRevenueMinusTaxes",
      summaryData?.totalRevenueMinusTaxes || 0
    );

    totalsRef.current["supplierCostTotal"] =
      summaryData?.supplierCostTotal || 0;
    totalsRef.current["totalRevenue"] = summaryData?.totalRevenue || 0;
    totalsRef.current["invoicedMM"] = summaryData?.invoicedMM || 0;
    totalsRef.current["totalRevenueMinusTaxes"] =
      summaryData?.totalRevenueMinusTaxes || 0;
  }, [summaryData]);

  const { mutate: updateSummary, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => ProjectSummaryService.update(state.id, state),
    {
      onSuccess: () => {
        toast.success("Status salvo com sucesso!", {
          pauseOnHover: false,
          autoClose: 2000,
        });
        getSummary();
      },
      onError: (error) => {
        toast.error("Não foi possível salvar o status", {
          pauseOnHover: false,
          autoClose: 2000,
        });
      },
    }
  );

  function checkEmptyValues(obj: any) {
    const blackList = [
      "comissionTotal",
      "invoicedMM",
      "profitability",
      "supplierCostTotal",
      "totalFees",
      "totalMarkupAndOverhead",
      "totalRevenue",
      "logisticTotal",
      "airTotal",
      "totalTaxes",
      "totalTaxesFee",
      "additionalTaxes",
      "totalRevenueMinusTaxes",
    ];

    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        !blackList.includes(key) &&
        obj[key] === 0
      ) {
        return true;
      }
    }
    return false;
  }

  const onSubmit = async (form: any) => {
    if (form?.taxPercentage < MIN_TAX_PERCENTAGE) {
      showNotification(
        "O percentual de imposto deve ser no mínimo 6,15 %",
        "error"
      );
      return false;
    }

    if (checkEmptyValues(form)) {
      setAlertModal(true);
      setTempForm(form);
    } else {
      updateSummary({
        id: summaryData?._id,
        project: projectId,
        status: SUMMARY_STATUS,
        ...form,
      });
    }

    return true;
  };

  const onAlertSubmit = async (form: any) => {
    if (form?.taxPercentage < MIN_TAX_PERCENTAGE) {
      showNotification(
        "O percentual de imposto deve ser no mínimo 6,15 %",
        "error"
      );
      return false;
    }

    updateSummary({
      id: summaryData?._id,
      project: projectId,
      status: SUMMARY_STATUS,
      ...form,
    });
    return true;
  };

  const makeDecision = (flag: boolean) => {
    if (flag) {
      updateSummary({
        id: summaryData?._id,
        project: projectId,
        status: SUMMARY_STATUS,
        ...tempForm,
      });
    }
    setAlertModal(false);
    setTempForm({});
  };

  function formatNumberToBRL(event: React.ChangeEvent<HTMLInputElement>): void {
    let userInput: string = event.target.value.replace(/[^0-9]/g, "");

    if (userInput === "") {
      setFormattedValue("R$ 0,00");
      setValue("additionalTaxes", 0);
    } else {
      let userInputAsNumber: number = parseInt(userInput, 10) / 100;

      let formattedNumber: string = `R$ ${userInputAsNumber
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}`;

      setFormattedValue(formattedNumber);
      setValue("additionalTaxes", Number(userInputAsNumber.toFixed(3)));
    }
  }

  return (
    <>
      <AlertModal
        handleClose={() => setAlertModal(false)}
        isOpen={alertModal}
        makeDecision={makeDecision}
        dontShowAgain={false}
        mainText="Existem valores preenchidos como zero no formulário. Deseja prosseguir ?"
      />

      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        style={{ borderRadius: 4 }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<FaCaretDown color="white" />}
          id="panel1a-header"
          style={{
            maxHeight: "40px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#D09C17",
            borderRadius: 4,
          }}
          onClick={() => setHasOpened(true)}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              {hasFilled ? <CompleteCircleIcon /> : <IncompleteCircleIcon />}
              <span style={{ color: "white" }}>Em aprovação</span>
            </div>
            {isLoadingUpdate ? (
              <CircularProgress
                style={{ color: "white", marginRight: 20 }}
                size={24}
              />
            ) : (
              canEdit && (
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                  {hasChanges && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 16px",
                        borderRadius: 4,
                        gap: 8,
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <CircleInfo />
                      <span style={{ color: "#FFFFFF" }}>
                        Alterações não salvas
                      </span>
                    </div>
                  )}
                  {hasChanges && (
                    <div
                      style={{
                        color: "#1976D2",
                        marginRight: 16,
                        cursor: "pointer",
                        padding: "8px 16px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        fontWeight: 600,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmit(onSubmit)();
                      }}
                    >
                      Salvar
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {loading ? (
            <Box
              display={"flex"}
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress style={{ margin: 16 }} />
            </Box>
          ) : (
            <FormProvider {...methods}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 2,
                }}
              >
                <SummarySection title="Previsão de Custo e Financeiro">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Valor Total do Evento"
                      name="totalValueOfEvents"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Pagamento Direto do Cliente"
                      name="directPaymentOfClients"
                      disabled={!canEdit}
                    />
                  </Box>
                </SummarySection>

                <span style={{ fontWeight: "bold" }}>Total de Receitas</span>

                <SummarySection title="Totais de receita Logistica / Produção">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Fee"
                      name="logisticFee"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Markup / Over"
                      name="logisticMarkup"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comissão"
                      name="logisticComission"
                      disabled={!canEdit}
                    />
                    <DynamicInput
                      label="Total Receitas"
                      name="logisticTotal"
                      value={formatter(watch("logisticTotal"))}
                      disabled
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Totais de receita Aéreo">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Fee"
                      name="airFee"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Markup / Over"
                      name="airMarkup"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comissão/Incentivo"
                      name="airComission"
                      disabled={!canEdit}
                    />
                    <DynamicInput
                      label="Total Receitas"
                      name="airTotal"
                      value={formatter(watch("airTotal"))}
                      disabled
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Outras receitas">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="RSVP"
                      name="RSVP"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comunicação"
                      name="communicationFee"
                      disabled={!canEdit}
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Impostos">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <DynamicInput
                      label="Total de impostos ISS/PIS/COFINS"
                      name="totalTaxes"
                      value={formatter(watch("totalTaxes"))}
                      disabled
                    />
                    <Box width={"100%"}>
                      <InputLabel sx={{ fontSize: 13 }}>Valor</InputLabel>
                      <TextField
                        onFocus={() => setLastFocus("value")}
                        onBlur={() => setLastFocus("")}
                        value={formatValue}
                        onChange={formatNumberToBRL}
                        disabled={!canEdit}
                      />
                    </Box>

                    <Box width={"100%"}>
                      <InputLabel sx={{ fontSize: 13 }}>
                        Percentual de imposto (%)
                      </InputLabel>

                      <NumberFormat
                        disabled={!canEdit}
                        onFocus={() => setLastFocus("percentage")}
                        onBlur={() => setLastFocus("")}
                        value={percent}
                        suffix=" %"
                        onChange={(e: any) => {
                          if (e.target.value === "") {
                            setPercent(null);
                            setValue("taxPercentage", 0);
                          } else {
                            const val = Number(
                              e.target.value.replace("%", "").replace(",", ".")
                            );
                            setPercent(val);
                            setValue("taxPercentage", val);
                          }
                        }}
                        decimalSeparator=","
                        customInput={TextField}
                        color={"info"}
                        size="small"
                      />

                      <span
                        style={{
                          fontSize: 14,
                          color: "rgba(1,1,1,0.5)",
                          marginTop: 4,
                        }}
                      >
                        Valor mínimo 6,15%
                      </span>
                    </Box>
                  </Box>
                </SummarySection>

                <SummarySection title="Resumo Financeiro - Totais">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <DynamicInput
                      label="Valor Total do Evento"
                      name="totalValueOfEvents"
                      value={formatter(watch("totalValueOfEvents"))}
                      disabled
                    />
                    <DynamicInput
                      label="Pag. Direto"
                      name="directPaymentOfClients"
                      value={formatter(watch("directPaymentOfClients"))}
                      disabled
                    />

                    <DynamicInput
                      label="Custo Fornecedor"
                      name="supplierCostTotal"
                      value={formatter(watch("supplierCostTotal"))}
                      disabled
                    />

                    <DynamicInput
                      label="Faturado MM"
                      name="invoicedMM"
                      value={formatter(watch("invoicedMM"))}
                      disabled
                    />
                  </Box>
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <DynamicInput
                      label="Impostos"
                      name="totalTaxesFee"
                      value={formatter(watch("totalTaxesFee"))}
                      disabled
                    />
                    <DynamicInput
                      label="Fee"
                      name="totalFees"
                      value={formatter(watch("totalFees"))}
                      disabled
                    />

                    <DynamicInput
                      label="Markup/Over"
                      name="totalMarkupAndOverhead"
                      value={formatter(watch("totalMarkupAndOverhead"))}
                      disabled
                    />
                    <DynamicInput
                      label="Comissão"
                      name="comissionTotal"
                      value={formatter(watch("comissionTotal"))}
                      disabled
                    />
                  </Box>
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <DynamicInput
                      label="Total de receitas"
                      name="totalRevenue"
                      value={formatter(watch("totalRevenue"))}
                      disabled
                    />

                    <DynamicInput
                      label="Total receita líquida - Impostos"
                      name="totalRevenueMinusTaxes"
                      value={formatter(watch("totalRevenueMinusTaxes"))}
                      disabled
                    />
                    <DynamicInput
                      label="Rentabilidade"
                      name="profitability"
                      value={formatPercentage(watch("profitability"))}
                      disabled
                    />

                    <Box width={"100%"} />
                  </Box>
                </SummarySection>

                <InputForm
                  label="Observações"
                  disabled={!canEdit}
                  name="observations"
                  placeholder="Exemplo: quem aprova o orçamento"
                />

                {hasChanges && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        cursor: "pointer",
                        padding: 16,
                        backgroundColor: "#1976D2",
                        borderRadius: 4,
                        fontWeight: 600,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmit(onSubmit)();
                      }}
                    >
                      Salvar
                    </div>
                  </Box>
                )}
              </Box>
            </FormProvider>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OnApprovalAccordion;
